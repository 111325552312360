import ReactDOM from 'react-dom/client';
// import { ActionButton, AsyncImage, BCol, BRow, Box, Column, Container, DeviceContainer, DeviceFlex, Flex, Link, LinkButton, ListBuilder, Loader, NavigationBar as Nav, NavLink, Row, StateIcon, SwitchActionButton, Text, htmlToElement } from 'react-builder';
import { htmlToElement } from '../node_modules/react-builder/dist/Core';

const e = document.getElementById('root') as HTMLElement;
const splash = document.getElementById('splash') as HTMLElement;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const preloadComponents = { BRow, BCol, Loader, ListBuilder, Link, SwitchActionButton, StateIcon, Text, Flex, DeviceFlex, Column, Box, Row, ActionButton, Nav, NavLink, AsyncImage, DeviceContainer, Container, LinkButton };

export const handleImport = (domain: string, componentName: string, module?: string) => {
  console.log(componentName);

  const componentId = (module ? `${module}/` : ``) + componentName;
  return domain == 'builder' ? import(`../../if-react-landing-page-builder/node_modules/react-builder/dist/widgets/${componentId}.js`)
    .then((e) => e.default) :
    import('./widgets/' + componentId)
      .then((e) => {
        return e.default;
      })
}

htmlToElement(e, '', {}, handleImport).then((_e: any) => {
  root.render(
    _e
  );
  setTimeout(() => {
    splash.style.display = "block";
  }, 10)
})